import { render, staticRenderFns } from "./dev-event-list.vue?vue&type=template&id=244a7176"
import script from "./dev-event-list.vue?vue&type=script&lang=js"
export * from "./dev-event-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports