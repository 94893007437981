<template>
  <div class="app-body">
    <div class="a-flex-rsbc">
      <bread-crumb></bread-crumb>
      <div class="a-flex-rcc">
        <el-button
          v-if="deviceInfo && deviceInfo.stationName"
          class="s-btn-danger a-mlr-12"
          icon="el-icon-unbind"
          @click="unbindDev"
        >
          解绑
        </el-button>
        <el-dropdown placement="bottom-start" @command="handlerDev">
          <el-button class="s-btn-exp" icon="el-icon-more">更多</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in optionsMore"
              :key="item.value"
              :command="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="devInfo">
      <div class="">
        <el-card class="dev-info-box">
          <div class="a-flex-rfsc">
            <span class="a-fs-16 a-fw-500 a-c-master">
              NO.{{ deviceInfo && deviceInfo.deviceCode ? deviceInfo.deviceCode : "" }}
            </span>
            <div
              :class="
                deviceInfo && deviceInfo.onlineStatus === 1 ? 'deviceStatus' : 'deviceStatusOff'
              "
              class="a-fs-14 a-flex-rcc"
            >
              {{ (deviceInfo ? deviceInfo.onlineStatus : "") | initOnlineStatus }}
            </div>
          </div>
          <div class="a-flex-rfsc a-flex-wrap a-mt-16">
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">序列号</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.serialNo ? deviceInfo.serialNo : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">设备ID</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.deviceId ? deviceInfo.deviceId : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">IMEI</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.imei ? deviceInfo.imei : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">ICCID</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.iccid ? deviceInfo.iccid : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">设备厂商</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.deviceFactory ? deviceInfo.deviceFactory : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">设备类型</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.deviceTypeName ? deviceInfo.deviceTypeName : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">插槽数</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.slotNum ? deviceInfo.slotNum : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">所属商户</span>
              <span
                v-if="deviceInfo && deviceInfo.companyName"
                class="dev-info-content a-fs-14 a-c-blue font-point"
                @click="toFranchiseeDetail"
              >
                {{ deviceInfo.companyName }}
              </span>
              <span v-else class="dev-info-content a-fs-14 a-c-master">-</span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">当前站点</span>
              <le-jumpto-detail
                v-if="deviceInfo && deviceInfo.stationName"
                class="dev-info-content a-fs-14 a-c-blue font-point"
                url="/station/station-detail"
                :d-query="{ stationId: deviceInfo.stationId }"
              >
                <span>{{ deviceInfo.stationName }}</span>
              </le-jumpto-detail>
              <span v-else class="dev-info-content a-fs-14 a-c-master">-</span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">是否启用</span>
              <span class="dev-info-content a-flex-rfsc a-fs-14 a-c-master">
                <span class="a-c-normal">
                  {{ deviceInfo && deviceInfo.canUse == 1 ? "已启用" : "已禁用" }}
                </span>
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">软件版本</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.softVersion ? deviceInfo.softVersion : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">硬件版本</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.hardwareVersion ? deviceInfo.hardwareVersion : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">入库时间</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.createTimeText ? deviceInfo.createTimeText : "-" }}
              </span>
            </div>
            <div class="a-flex-rfsfs a-mt-16">
              <span class="dev-info-title a-fs-14 a-c-normal">安装时间</span>
              <span class="dev-info-content a-fs-14 a-c-master">
                {{ deviceInfo && deviceInfo.installTimeText ? deviceInfo.installTimeText : "-" }}
              </span>
            </div>
          </div>
          <el-tabs v-model="tabs" class="a-mt-16 devicetabs" @tab-click="handleTabsClick">
            <el-tab-pane label="实时信息" name="0"></el-tab-pane>
            <el-tab-pane label="绑定/解绑记录" name="1"></el-tab-pane>
            <el-tab-pane label="7天流水日志" name="2"></el-tab-pane>
            <el-tab-pane label="事件记录" name="3"></el-tab-pane>
            <el-tab-pane label="在线记录" name="4"></el-tab-pane>
          </el-tabs>
        </el-card>
        <div class="dev-info-content-card a-mt-16">
          <dev-realtime-info
            v-if="tabs == '0'"
            style="padding-top: 24px"
            :device-code="deviceCode"
          ></dev-realtime-info>
          <dev-bind-list
            v-if="tabs == '1'"
            style="padding-top: 8px"
            :device-code="deviceCode"
          ></dev-bind-list>
          <dev-log v-if="tabs == '2'" :device-code="deviceCode"></dev-log>
          <dev-event-list v-if="tabs == '3'" :device-code="deviceCode"></dev-event-list>
          <dev-online-list
            v-if="tabs == '4'"
            style="padding-top: 8px"
            :device-code="deviceCode"
          ></dev-online-list>
        </div>
      </div>
      <dev-param-set ref="paramSet" @paramsSet="paramsSetResult"></dev-param-set>
      <dev-param-result ref="paramResult" @resetParams="resetParams"></dev-param-result>
      <dev-reboot-result ref="rebootResult" :table-data="rebootResult"></dev-reboot-result>
    </div>
    <a-poppup ref="aPoppup">
      <div class="pop-content a-flex-rcc">
        <div class="progress">
          <div class="progress-item"></div>
        </div>
      </div>
    </a-poppup>
  </div>
</template>

<script>
import APoppup from "../components/poppup/a-poppup.vue";
import DevBindList from "./child/dev-bind-list.vue";
import DevEventList from "./child/dev-event-list.vue";
import DevLog from "./child/dev-log.vue";
import DevOnlineList from "./child/dev-online-list.vue";
import DevParamResult from "./child/dev-param-result.vue";
import DevParamSet from "./child/dev-paramSet.vue";
import devRealtimeInfo from "./child/dev-realtime-info.vue";
import DevRebootResult from "./child/dev-reboot-result.vue";
export default {
  components: {
    devRealtimeInfo,
    DevBindList,
    DevLog,
    DevEventList,
    DevOnlineList,
    APoppup,
    DevRebootResult,
    DevParamSet,
    DevParamResult,
  },
  filters: {
    initcsq(val) {
      if (val && val >= 18) {
        return "强(-1db)";
      } else if (val && val >= 11 && val < 18) {
        return "中(-50db)";
      } else if (val && val < 11) {
        return "弱(-98db)";
      } else {
        return "无信号";
      }
    },
    initOnlineStatus(val) {
      if (val === 1) {
        return "在线";
      } else if (val === 2) {
        return "故障";
      } else {
        return "离线";
      }
    },
  },
  data() {
    return {
      deviceInfo: null,
      optionsMore: [
        {
          label: "远程重启",
          value: 1,
        },
        {
          label: "参数下发",
          value: 2,
        },
        {
          label: "编辑",
          value: 3,
        },
      ],
      deviceCode: "", //设备code
      tabs: "0",
      rebootResult: [],
    };
  },
  computed: {
    csqStyle() {
      const val = this.deviceInfo && this.deviceInfo.csq ? this.deviceInfo.csq : "";
      if (val && val >= 18) {
        return "color: #29CC29";
      } else if (val && val >= 11 && val < 18) {
        return "color: #FFA900";
      } else if (val && val < 11) {
        return "color: #FF3B30";
      } else {
        return "color: #606366";
      }
    },
  },
  mounted() {
    this.deviceCode = this.$route.query.deviceCode;
    if (this.deviceCode) this.getDeviceInfo();
  },
  methods: {
    // 获取设备信息
    getDeviceInfo() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDeviceBaseInfo,
          method: "get",
          params: {
            deviceCode: this.deviceCode,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.deviceInfo = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 切换选项卡
    handleTabsClick(tab) {
      this.tabs = tab.name;
    },
    // 设备解绑
    unbindDev() {
      this.$confirm("是否确认解除绑定?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._post({
            url: this.$Config.apiUrl.batchUnBindDevice,
            method: "post",
            params: {
              deviceCodes: [this.deviceCode],
            },
          })
          .then((res) => {
            if (res.result.code == 0) {
              this.$message.success("操作成功");
              this.getDeviceInfo();
              this.tabs = "0";
            } else {
              this.$message.error(res.result.message);
            }
          });
      });
    },
    handlerDev(command) {
      const arr = [
        {
          deviceCode: this.deviceCode,
          deviceType: this.deviceInfo.deviceType,
        },
      ];
      switch (command) {
        case 1:
          // 重启
          this.$confirm("是否确认远程重启所选设备?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.batchRebootDev([this.deviceCode]);
          });
          break;
        case 2:
          // 参数下发
          this.devParamSet(arr);
          break;
        case 3:
          // 编辑
          this.$router.push({
            path: "/device/device-edit",
            query: {
              deviceCode: this.deviceCode,
            },
          });
          break;
        default:
          break;
      }
    },
    // 远程重启
    batchRebootDev(datas) {
      this.showPop();
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchReboot,
          method: "post",
          noShowLoading: true,
          params: {
            deviceCodes: datas,
          },
        })
        .then((res) => {
          this.$refs["aPoppup"].close();
          if (res.result.code == 0) {
            this.rebootResult = res.result.data;
            this.$refs["rebootResult"].dialogVisible = true;
            this.getDeviceInfo();
          } else {
            this.$message.error(res.result.message);
          }
        })
        .catch(() => {
          this.$refs["aPoppup"].close();
        });
    },
    // 参数下发
    devParamSet(datas, type = "single") {
      this.$refs.paramSet.devDatas = datas;
      this.$refs.paramSet.setType = type;
      this.$refs.paramSet.deviceId = this.deviceInfo.deviceId;
      this.$refs.paramSet.dialogVisible = true;
    },
    paramsSetResult(datas) {
      this.$refs.paramResult.tableData = datas;
      this.$refs.paramResult.dialogVisible = true;
    },
    // 下发参数重试
    resetParams(datas) {
      this.devParamSet(datas, "reset");
    },
    // 显示弹窗
    showPop() {
      this.$refs["aPoppup"].open({
        title: "设备重启中",
        subTitle: "预计等待时间1分30秒",
        showConfirm: true,
        showCancel: false,
        confirmText: "确认",
        cancelText: "取消",
      });
    },
    //跳转商户详情
    toFranchiseeDetail() {
      // if (this.deviceInfo.status == 2) {
      //   this.$router.push({
      //     path: "/franchisee/franchisee-contract?code=" + this.deviceInfo.companyId,
      //   });
      // } else if (this.deviceInfo.status == 3) {
      //   this.$router.push({
      //     path: "/franchisee/franchisee-change?code=" + this.deviceInfo.companyId,
      //   });
      // } else {
      this.$router.push({
        path: "/franchisee/franchisee-detail?code=" + this.deviceInfo.companyId,
      });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.devInfo {
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  font-size: 14px;
}
.deviceStatus {
  width: 58px;
  height: 24px;
  background: #e6ffe6;
  border-radius: 4px;
  margin-left: 8px;
  color: #29cc29;
}
.deviceStatusOff {
  width: 58px;
  height: 24px;
  background: rgba($color: #ff3b30, $alpha: 0.2);
  border-radius: 4px;
  margin-left: 8px;
  color: #ff3b30;
}
.dev-info-title {
  width: 80px;
}
.dev-info-content {
  width: 380px;
  margin-right: 37px;
}
.width80 {
  width: 80px;
}
.width280 {
  width: 280px;
}
/deep/ .date-range {
  margin-left: 0 !important;
}
.dev-info-content-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 0 24px 24px;
}
.dev-info-box {
  padding: 4px !important;
}

/deep/ .el-icon-unbind {
  background: url("../../assets/icon/unbind.png") center no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  font-size: 12px;
}
/deep/ .el-icon-unbind:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}
.devicetabs {
  margin-bottom: -36px;
}
.progress {
  width: 205px;
  height: 7px;
  border-radius: 4px;
  margin: 13px 0 24px;
  background: #b9b9b9;
}
.progress-item {
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background: #007aff;
  animation: progressPop 10s;
  -webkit-animation: progressPop 5s; /* Safari and Chrome */
}
/* 动画 */
@keyframes progressPop {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes progressPop /* Safari and Chrome */ {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/deep/ .el-tabs__item {
  // color: #007aff;
  font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
  color: #007aff;
  font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
  background: none !important;
}
</style>
