<template>
  <div>
    <le-pagview :page-param="pageParam" @setData="setTableData">
      <el-table :data="tabledata">
        <el-table-column label="操作类型" prop="typeText"></el-table-column>
        <el-table-column label="所属站点" prop="stationName"></el-table-column>
        <el-table-column label="绑定/解绑途径" prop="appIdText"></el-table-column>
        <el-table-column label="操作人" prop="operatorUserName"></el-table-column>
        <el-table-column label="操作时间" prop="createTimeText"></el-table-column>
      </el-table>
    </le-pagview>
  </div>
</template>

<script>
export default {
  props: ["deviceCode"],
  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getDeviceOptionTimeline,
        method: "post",
        params: {
          deviceCode: this.deviceCode, // 设备码
        },
        freshCtrl: 1,
      },
      tabledata: [],
    };
  },
  methods: {
    setTableData(datas) {
      this.tabledata = datas;
    },
  },
};
</script>
