<template>
  <div>
    <le-pagview :page-param="pageParam" @setData="setTableData">
      <div class="a-flex-rfsc">
        <le-date-range large label="" :min-date.sync="startTime" :max-date.sync="endTime" />
      </div>
      <el-table :data="tabledata">
        <el-table-column label="事件类型" prop="typeText"></el-table-column>
        <el-table-column label="详细说明" prop="content"></el-table-column>
        <el-table-column label="订单ID" prop="orderId"></el-table-column>
        <el-table-column label="处理人" prop="operatorUserName"></el-table-column>
        <el-table-column label="处理时间" prop="createTimeText"></el-table-column>
      </el-table>
    </le-pagview>
  </div>
</template>

<script>
export default {
  props: ["deviceCode"],
  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getDeviceEvent,
        method: "post",
        params: {
          deviceCode: this.deviceCode, // 设备码
          startTime: "",
          endTime: "",
        },
        freshCtrl: 1,
      },
      tabledata: [],
      startTime: "",
      endTime: "",
    };
  },
  watch: {
    startTime(newval) {
      this.pageParam.params.startTime = newval;
      this.pageParam.freshCtrl++;
    },
    endTime(newval) {
      this.pageParam.params.endTime = newval;
      this.pageParam.freshCtrl++;
    },
  },
  methods: {
    setTableData(datas) {
      this.tabledata = datas;
    },
  },
};
</script>
