<template>
  <div>
    <le-pagview :page-param="pageParam" @setData="setTableData">
      <el-table :data="tabledata">
        <el-table-column width="128" label="类型" prop="typeText"></el-table-column>
        <el-table-column
          min-width="200"
          label="在线/离线时间"
          prop="createTimeText"
        ></el-table-column>
      </el-table>
    </le-pagview>
  </div>
</template>

<script>
export default {
  props: ["deviceCode"],
  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getDeviceOnlineRecord,
        method: "post",
        params: {
          deviceCode: this.deviceCode, // 设备码
        },
        freshCtrl: 1,
      },
      tabledata: [],
    };
  },
  methods: {
    setTableData(datas) {
      this.tabledata = datas;
    },
  },
};
</script>
