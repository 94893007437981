<template>
  <div>
    <le-pagview :page-param="pageParam" @setData="setTableData">
      <div class="a-flex-rfsc">
        <le-input-date v-model="dateVal" label="" style="margin-left: 0"></le-input-date>
      </div>
      <el-table :data="tabledata">
        <el-table-column label="上行/下行" prop="upOrDown">
          <template slot-scope="{ row }">
            <span>{{ row.upOrDown == "down" ? "下行" : "上行" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备报文" prop="content"></el-table-column>
        <el-table-column label="报文描述" prop="decodeContent"></el-table-column>
        <el-table-column label="日志时间" prop="timeText"></el-table-column>
      </el-table>
    </le-pagview>
  </div>
</template>

<script>
export default {
  props: ["deviceCode"],
  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getDeviceLog,
        method: "post",
        params: {
          deviceCode: this.deviceCode, // 设备码
          date: "",
        },
        freshCtrl: 1,
      },
      dateVal: "",
      tabledata: [],
    };
  },
  watch: {
    dateVal: {
      immediate: true,
      handler(newval) {
        this.pageParam.params.date = newval;
        if (!newval && this.pageParam.freshCtrl != 1) {
          this.$message.error("请选择日期");
          return;
        }
        this.pageParam.freshCtrl++;
      },
    },
  },
  created() {
    const dateVal = new Date();
    this.dateVal = this.$Utils.formatDate(dateVal, "yyyy-MM-dd");
  },
  methods: {
    setTableData(datas) {
      this.tabledata = datas;
    },
    getCurrentDate() {},
  },
};
</script>
