<template>
  <div>
    <div class="a-flex-rsbc">
      <div class="a-flex-rfsc">
        <span class="a-fs-14 a-c-normal width80">检测时间</span>
        <span class="a-fs-14 a-c-master width280">{{ timeStampText }}</span>
        <span class="a-fs-14 a-c-normal width80">检测结果</span>
        <span
          class="a-fs-14 width280"
          :class="resultTextCode == 1 ? 'dev-check-success' : 'dev-check-danger'"
        >
          {{ resultTextCode == 1 ? "成功" : "失败" }}
        </span>
      </div>

      <el-button type="primary" @click="getTableDataManual">检测</el-button>
    </div>
    <div class="a-flex-rfsc a-mt-10">
      <span class="a-fs-14 a-c-normal width80">信号强度</span>
      <span class="a-fs-14 dev-check-success width280" :style="csqStyle">
        {{ csqStatus.csq | initcsq }}
      </span>
      <span class="a-fs-14 a-c-normal width80">检测时间</span>
      <span class="a-fs-14 a-c-master width280">{{ csqStatus.timeStampText }}</span>
    </div>
    <div class="a-flex-rfsc a-mt-15">
      <span class="a-fs-14 a-c-normal width80">整机温度</span>
      <span class="a-fs-14 dev-check-success width280">{{ tempStatus.temp }}℃</span>
      <span class="a-fs-14 a-c-normal width80">检测时间</span>
      <span class="a-fs-14 a-c-master width280">{{ tempStatus.timeStampText }}</span>
    </div>
    <el-table :data="tabledata" class="a-mt-16">
      <el-table-column label="端口号">
        <template slot-scope="{ row }">
          <span>{{ row.slot + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="功率(W)" prop="power"></el-table-column>
      <el-table-column label="电流(A)" prop="current"></el-table-column>
      <el-table-column label="电压(V)" prop="voltage"></el-table-column>
      <el-table-column label="检测时间" prop="timeStampText"></el-table-column>
      <!-- <el-table-column label="温度(℃)" prop="temperature">
                <template slot-scope="{ row }">
                    <span class="a-c-red" v-if="row.temperature>=60">{{ row.temperature }}!</span>
                    <span v-else>{{ row.temperature }}</span>
                </template>
            </el-table-column> -->
      <el-table-column label="是否在充电">
        <template slot-scope="{ row }">
          <span>{{ row.status === 1 ? "充电中" : "空闲" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <a-poppup
      ref="aPoppup"
      title="设备检测中"
      sub-title="请勿离开当前页面，等待检测结果"
      :show-cancel="false"
    >
      <div class="pop-content a-flex-rcc">
        <!-- <el-progress class="progress" :stroke-width="8" :percentage="percentage" color="#007AFF" :show-text="false"></el-progress> -->
        <div class="progress">
          <div class="progress-item"></div>
        </div>
      </div>
    </a-poppup>
  </div>
</template>

<script>
import APoppup from "../../components/poppup/a-poppup.vue";
export default {
  components: { APoppup },
  filters: {
    initcsq(val) {
      if (val && val >= 18) {
        return "强(csq:" + val + ")";
      } else if (val && val >= 11 && val < 18) {
        return "中(csq:" + val + ")";
      } else if (val && val < 11) {
        return "弱(csq:" + val + ")";
      } else {
        return "无信号";
      }
    },
  },
  props: ["deviceCode"],
  data() {
    return {
      tabledata: [],
      csqStatus: {
        csq: 0,
      }, //信号
      tempStatus: {
        temp: 0,
      },
      timeStampText: "", //检测时间
      resultTextCode: 1,
    };
  },
  computed: {
    csqStyle() {
      const val = this.csqStatus.csq;
      if (val && val >= 18) {
        return "color: #29CC29";
      } else if (val && val >= 11 && val < 18) {
        return "color: #FFA900";
      } else if (val && val < 11) {
        return "color: #FF3B30";
      } else {
        return "color: #606366";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTableData();
    });
  },
  methods: {
    getTableData() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDeviceRealTimeInfo,
          method: "get",
          params: {
            deviceCode: this.deviceCode,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.resultTextCode = 1;
            this.csqStatus = res.result.data.csqStatus;
            this.tempStatus = res.result.data.tempStatus;
            this.timeStampText = res.result.data.timeStampText;
            this.tabledata = Object.values(res.result.data.slotStatus);
          } else {
            this.$message.error(res.result.message);
            this.resultTextCode = 2;
            this.csqStatus = res.result.data.csqStatus;
            this.tempStatus = res.result.data.tempStatus;
            this.timeStampText = res.result.data.timeStampText;
            this.tabledata = Object.values(res.result.data.slotStatus);
          }
        })
        .catch(() => {
          this.resultTextCode = 2;
        });
    },
    getTableDataManual() {
      this.showPop();
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDeviceRealTimeInfoManual,
          method: "get",
          noShowLoading: true,
          params: {
            deviceCode: this.deviceCode,
          },
        })
        .then((res) => {
          this.$refs["aPoppup"].close();
          if (res.result.code == 0) {
            this.$message.success("检测成功");
            this.resultTextCode = 1;
            this.csqStatus = res.result.data.csqStatus;
            this.tempStatus = res.result.data.tempStatus;
            this.timeStampText = res.result.data.timeStampText;
            this.tabledata = Object.values(res.result.data.slotStatus);
          } else {
            this.resultTextCode = 2;
            this.csqStatus = res.result.data.csqStatus;
            this.tempStatus = res.result.data.tempStatus;
            this.timeStampText = res.result.data.timeStampText;
            this.tabledata = Object.values(res.result.data.slotStatus);
            this.$message.error(res.result.message);
          }
        })
        .catch(() => {
          this.resultTextCode = 2;
          this.$refs["aPoppup"].close();
        });
    },
    // 显示弹窗
    showPop() {
      this.$refs["aPoppup"].open({
        title: "设备检测中",
        subTitle: "请勿离开当前页面，等待检测结果",
        showConfirm: true,
        showCancel: false,
        confirmText: "确认",
        cancelText: "取消",
      });
    },
  },
};
</script>

<style scoped>
.dev-check-success {
  color: #29cc29;
}
.dev-check-danger {
  color: #ff3b30;
}
.width80 {
  width: 80px;
}
.width280 {
  width: 280px;
}
.progress {
  width: 205px;
  height: 7px;
  border-radius: 4px;
  margin: 13px 0 24px;
  background: #b9b9b9;
}
.progress-item {
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background: #007aff;
  animation: progressPop 10s;
  -webkit-animation: progressPop 5s; /* Safari and Chrome */
}
/* 动画 */
@keyframes progressPop {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes progressPop /* Safari and Chrome */ {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
>
